<!--  -->

<template>
  <div class="cobros_pagos_parcialFras">   
      <div>
        <v-sheet :elevation="4">        
          <!-- Cabecera -->
          <div class="cabecera">
            <base_Header
              v-if="Entorno.header.header"       
              :Entorno="Entorno.header"
              @onEvent="$emit('onEvent', { accion:'cerrar' })">
            </base_Header>                  
          </div>


          <!-- Contenido -->
          <div class="contenedor" style="width:500px"> 
            <div class="columna">
              <div>
                <v-btn 
                  v-bind="$cfg.btra_cfg.standard"
                  title="Pago parcial"
                  @click="pago_parcial()">
                    <v-icon>{{'mdi-check'}}</v-icon>
                </v-btn>
                <v-btn class="ml-1"
                  v-bind="$cfg.btra_cfg.standard"
                  @click="cerrar()">
                    <v-icon dark>{{ "mdi-close" }}</v-icon>
                </v-btn>              
              </div>              
              
              <vllabel        
                style="width:100%;margin-top:10px"        
                label="Cuenta"            
                :txt="auxRecord.cta">
              </vllabel>

              <div class="conflex">
                <vllabel    
                  style="width:50%"             
                  label="Factura"            
                  :txt="accionRow.fra_doc">
                </vllabel>

                <vllabel 
                  style="width:50%"                
                  label="Fecha"            
                  :txt="accionRow.fra_fh|fecha">
                </vllabel>
              </div>
               <div class="conflex">
                <vllabel                    
                  style="width:50%"            
                  label="Pendiente"            
                  :txt="accionRow.imp|num(2)">
                </vllabel>            

                <uiText
                  @Enter="pago_parcial()"
                  class="green lighten-5"
                  v-model="schema.ctrls.pte.value"
                  label="Importe a PAGAR (parcial)"
                  format="money">                  
                </uiText>

                <!-- <v-text-field reverse
                  class="green lighten-5"
                  v-bind="$input"
                  v-model="schema.ctrls.pte.value"
                  label="Importe a PAGAR (parcial)">
                </v-text-field> -->

              </div>            
            </div>
          </div>
        </v-sheet> 
      </div>
  </div>
</template>



<script>

  import { mixinCtrls } from "@/mixins/mixinCtrls.js";
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base");
 
  export default {
    mixins: [mixinCtrls],
    components: { base_Header },
    props: {
      padre: { type:String, default: '' }, 
      accion: { type:String, default: ''},
      accionRow: { type:Object, default: null},      
      auxRecord: { type:Object, default: null},      
      componenteTipo: { type:String, default:'M' },
      Entorno: { type:Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.F)); }}
    },

    data() {
      return {
        schema: {
          ctrls: {          
            pte: { f:'pte', label:'Pendiente', value:""}
          }
        },
        api:'',
        stName:'stFcobros_pagos_parcialFras',      
      };
    },


    created() {
      this.ini_data();
      this.ini_schemaCtrls();
    },


    methods: {
      ini_data() {
        console.log("DEV " + this.api + " ********************** (ini_data)");

        // configuro Entorno 
        this.Entorno.header.titulo = "Cobro/Pago Parcial";        
      },


      //
      ini_schemaCtrls() {
        this.record2ctrl(this.accionRow, this.schema.ctrls);
      },


      //
      pago_parcial() {

        if (this.schema.ctrls.pte.value== "0") {
          this.$root.$alert.open('Debe de asignar una cantidad', 'error');
          return;
        }

        // cojo objeto record recibido y le modifico el campo pte
        let record= this.accionRow;
        record.pte= this.schema.ctrls.pte.value;

        this.$root.$alert.open("<br>IMPORTE a Pagar modificado: <b>" + this.schema.ctrls.pte.value + "</b>,<br> ya puedes pulsar 'LIQUIDAR' para el Cobro/Pago de la/s factura/s ", 'success', 3000);
        
        this.$store.commit(this.padre + '/update_record', { opcion: 'guardar', record: record, id: record.id });       
        this.$emit('update_parcial', { record:record });
        this.cerrar();
      },

      cerrar() {
        this.$emit('onEvent', { accion:'cerrar' });
      },


    }
  };
</script>
